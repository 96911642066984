@import "variables/colors.scss";

%input {
    line-height: 1.5;
    height: 3.5rem;
    width: 100%;
    padding: 0.8rem 1.0625rem;
    color: var(--primary-color-font);
    border: 2px solid var(--primary-color-background);
    border-radius: 0;
    transition: all 0.3s ease;
    -webkit-appearance: none;
    &:not(:last-child) {
        margin-bottom: 0.625rem;
    }
    &::placeholder {
        color: get-color("text", "medium", 0.6);
    }
    &:focus {
        background-color: get-color("background", "light");
        border-color: var(--primary-color-background);
        &::placeholder {
            color: get-color("text", "medium", 0.9);
        }
    }
    &:focus:invalid {
        border: 2px solid $error-2;
    }
}
