@import "../../../styles/variables";

.container {
    overflow: hidden;
    position: relative;
    background-color: get-color("background");

    &Inner {
        position: relative;
        z-index: 1;
    }

    &BackgroundImage {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        clip: rect(0, auto, auto, 0);

        &:after {
            content: "";
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            // Using vh intentionally instead of percentage, as
            // iOS doesn't update this value after it being set.
            height: 100vh;
            background-image: var(--background-image-mobile-url);
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat;
            @include respond-to("medium") {
                background-image: var(--background-image-url);
            }
        }
    }
}

.title {
    font-family: $font-secondary;
    font-size: get-font-size("heading one", em);
    color: var(--primary-color-background);

    @include respond-to("medium") {
        font-size: get-font-size("small title", em);
    }

    &HasCaps {
        text-transform: uppercase;
    }
}
