@import "../../../styles/variables";

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

.base {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;
    padding: 0;
    appearance: none;
}

.text {
    display: block;
    margin-right: 1.125rem;
    font-family: $font-primary;
    &White {
        color: get-color("text", "light");
    }
}
.icon {
    position: relative;
}
.iconBackground {
    height: 3.125rem;
    width: 3.125rem;
    background-color: var(--primary-color-background);
    border-radius: 2rem;
    box-shadow: $readmore-shadow;
    transition: all 0.3s ease;

    &:hover {
        background-color: get-color("background", "base") !important;

        & + .iconArrow path {
            stroke: var(--primary-color-background);
        }
    }

    &Invert {
        background-color: get-color("background", "base");

        &:hover {
            background-color: var(--primary-color-background) !important;

            & + .iconArrow path {
                stroke: get-color("background", "base");
            }
        }
    }
    &Animate {
        animation: pulse 0.5s 3;
    }
}
.iconArrow {
    position: absolute;
    height: 1rem;
    width: 0.5rem;
    left: 50%;
    top: 50%;
    pointer-events: none;
    transform: translate(-50%, -50%);

    path {
        stroke: get-color("background", "base");
    }

    &Invert {
        path {
            stroke: var(--primary-color-background);
        }
    }
}
