@import "../../styles/variables";

.loaderWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 26.3125rem);
    background: get-color("background", "light");
    @include respond-to("medium") {
        min-height: calc(100vh - 18.125rem);
    }
}
