@import "../../../styles/variables";

.container {
    margin-top: 0.5rem;

    @include respond-to("medium") {
        margin-top: 2.5rem;
    }

    a {
        color: var(--primary-color-background);
    }
}
