@import "../../../styles/helpers";

.title {
    font-family: $font-secondary;
    font-size: get-font-size("heading one", em);
    color: var(--primary-color-background);

    @include respond-to("medium") {
        font-size: get-font-size("small title", em);
    }

    &HasCaps {
        text-transform: uppercase;
    }
}
