@import "../../../../styles/variables";

.base {
    position: relative;
    height: 35rem;
    width: 100%;
    overflow: hidden;
    background-color: var(--primary-color-background);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @include respond-to("large") {
        height: 37.5rem;
    }
}

.overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(black, 0.3);
}

.content {
    position: absolute;
    width: 100%;
    padding: 2.125rem;
    top: 50%;
    color: $white;
    text-align: center;
    transform: translateY(-50%);
}
.icon {
    height: 3.9375rem;
    margin-bottom: 1.75rem;
}
.title {
    color: inherit !important;
    &A {
        margin-bottom: 1rem;
        font-size: get-font-size("heading one", em);
    }
    &B {
        font-size: get-font-size("megatitle", em);
        text-shadow: $text-shadow;
    }
    @include respond-to("medium") {
        max-width: 57.25rem;
        margin-left: auto;
        margin-right: auto;
        font-size: get-font-size("megatitle desktop", em);
        &A {
            margin-bottom: 2rem;
        }
    }
    @include respond-to("large") {
        &B {
            margin-top: 6.125rem;
            margin-bottom: 5.125rem;
        }
    }
}

.video {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
}

.text {
    margin-bottom: 4rem;
    font-size: get-font-size("base large", em);
    @include respond-to("small") {
        max-width: 28.75rem;
        margin: 0 auto 3rem auto;
    }
}
