@import "../../../../styles/variables";

.base {
    width: 100%;
    margin-top: 8rem;
    padding: 0.9375rem 0;
    color: get-color("text", "light");
    background-color: $pink;
    @include respond-to("medium") {
        padding: 2.75rem 0;
        margin: 8rem 0;
    }
    @include respond-to("large") {
        margin: 10rem 0;
    }
}
.baseWrap {
    @include respond-to("medium") {
        display: flex;
        align-items: stretch;
        max-width: 76.875rem;
    }
}
.animationWrap {
    position: relative;
    display: block;
    height: 8rem;
    @include respond-to("medium") {
        height: auto;
        width: 50%;
    }
}
.animation {
    position: absolute;
    top: -6.5rem;
    @include respond-to("medium") {
        width: calc(100% + 5rem);
        padding-right: 2rem;
        left: -15%;
        bottom: -6.5rem;
        display: flex;
        justify-content: flex-end;
    }
    @include respond-to("large") {
        width: 100%;
        left: 0;
        top: -8rem;
        bottom: -7.5rem;
    }
    @include respond-to("x-large") {
        padding-right: 4rem;
    }
}
.imageLeft {
    max-height: 12rem;
    margin-left: 1.5rem;
    transform: rotate(6deg) scale(1);
    transition: transform 0.1s ease;

    @include respond-to("medium") {
        max-height: none;
        height: 14rem;
    }
    @include respond-to("large") {
        height: 17rem;
        padding-right: 2rem;
    }
}
.imageRight {
    display: none;
    @include respond-to("medium") {
        display: block;
        align-self: flex-end;
        height: 18rem;
    }
    @include respond-to("large") {
        height: 22rem;
    }
}
.animationBubbles {
    margin-left: 1.4rem;
    opacity: 0;
    transition: opacity 0.1s ease;

    @include respond-to("medium") {
        position: absolute;
        margin-left: 0;
        right: 22%;
        top: 2rem;
    }
    @include respond-to("large") {
        right: 33%;
        top: 1rem;
    }
    @include respond-to("x-large") {
        right: 37%;
    }
}
.content {
    @include respond-to("medium") {
        width: 50%;
    }
}
.contentWrap {
    @include respond-to("medium") {
        max-width: 27.75rem;
    }
}
.title {
    margin-bottom: 0.5rem;
    color: inherit !important;
    opacity: 0;
    transform: translateX(-3rem);

    @include respond-to("medium") {
        opacity: 1;
        transform: translateX(0);
    }
}
.text {
    display: none;
    @include respond-to("medium") {
        display: block;
        font-size: get-font-size("base large", em);
        line-height: 1.5;
    }
}
