@import "../../../styles/variables";

%navy-button {
    color: get-color("text", "light", 1);
    background-color: var(--primary-color-background);
    &:focus {
        box-shadow: 0 0 0 4px get-color("theme", "light", 1);
    }
}
%white-button {
    color: var(--primary-color-font) !important;
    background-color: get-color("background", "base", 1) !important;
    &:focus {
        box-shadow: 0 0 0 4px get-color("theme", "light", 1);
    }
}
%lightgrey-button {
    color: var(--primary-color-font);
    background-color: get-color("accent", "light", 1);
    &:focus {
        box-shadow: 0 0 0 4px get-color("theme", "light", 1);
    }
}
%bluebird-button {
    color: get-color("text", "light", 1);
    background-color: var(--secondary-color-background);
    &:focus {
        box-shadow: 0 0 0 4px get-color("theme", "light", 1);
    }
}

.button {
    display: block;
    width: 100%;
    max-width: 15.125rem;
    padding: 0.85rem;
    margin: 0 auto;
    font-family: $font-primary;
    text-align: center;
    appearance: none;
    cursor: pointer;
    box-shadow: $button-shadow;
    transition: all 0.2s ease-in-out;
    border-radius: 3rem;

    &White {
        @extend %white-button;
        &:hover {
            @extend %navy-button;
        }
    }
    &Navy {
        @extend %navy-button;
        &:hover {
            @extend %white-button;
        }
    }
    &Lightgrey {
        @extend %lightgrey-button;
        &:hover {
            @extend %bluebird-button;
        }
    }
    &Bluebird {
        @extend %bluebird-button;
        &:hover {
            @extend %lightgrey-button;
        }
    }

    &HasCaps {
        text-transform: uppercase;
    }

    &IsSquare {
        border-radius: 0;
    }

    &IsSquareRounded {
        border-radius: 0.375rem;
    }

    &UsePrimaryFont {
        font-family: $font-primary;
    }

    &UseSecondaryFont {
        font-family: $font-secondary;
    }
}
