@import "../../../styles/variables";

.hamburger {
    font: inherit;
    display: inline-block;
    overflow: visible;
    margin: 0;
    padding: 15px;
    width: auto;
    cursor: pointer;
    transition-timing-function: linear;
    transition-duration: 0.15s;
    transition-property: opacity, filter;
    text-transform: none;
    color: currentColor;
    border: 0;
    background-color: transparent;
    box-shadow: 0 0 0 0;
    border-radius: 0;

    &:hover {
        color: currentColor;
        background-color: transparent;
    }
}

.label {
    margin-right: 10px;
    font-weight: 100;

    .hamburger.IsActive & {
        opacity: 0;
    }
}

.box {
    position: relative;
    display: inline-block;
    width: 26px;
    height: 16px;
}

.inner,
.innerAfter,
.innerBefore {
    position: absolute;
    width: 26px;
    height: 2px;
    transition-timing-function: ease;
    transition-duration: 0.15s;
    transition-property: transform;
    border-radius: 4px;
    background-color: #fff;
}

.inner {
    top: 50%;
    display: block;
    // elastic
    top: 4px;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition-duration: 0.275s;

    .hamburger.IsActive & {
        transition-delay: 75ms;
        transform: translate3d(0, 10px, 0) rotate(135deg);
        background-color: #fff;
        top: 0;
    }

    &After,
    &Before {
        display: block;
        content: "";

        .hamburger.IsActive & {
            background-color: #fff;
        }
    }

    &Before {
        top: 9px;
        transition: opacity 0.125s ease 0.275s;

        .hamburger.IsActive & {
            transition-delay: 0s;
            opacity: 0;
        }
    }

    &After {
        bottom: -10px;
        top: 14px;
        transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);

        .hamburger.IsActive & {
            top: 19px;
            transition-delay: 75ms;
            transform: translate3d(0, -10px, 0) rotate(-135deg);
        }
    }
}
