// helpers
@import "~normalize.css/normalize.css";
@import "variables";
// foundation
@import "foundation/base";
@import "foundation/typography";

:root {
    --primary-color-background: #222;
    --secondary-color-background: #111;
    --primary-color-font: #222;
    --placeholder-color-font: get-color("text", "medium", 0.6);
}

:global {
    .loader {
        &Full {
            position: absolute;
            height: 100vh;
            width: 100%;
            display: flex;
            flex: 1;
            background: get-color("background", "light");
            align-items: center;
            justify-content: center;
            top: 0;
            z-index: 5;
        }
    }

    .loaderRing {
        display: inline-block;
        position: relative;
        width: 64px;
        height: 64px;

        div {
            box-sizing: border-box;
            display: block;
            position: absolute;
            width: 51px;
            height: 51px;
            margin: 6px;
            border: 6px solid var(--primary-color-background);
            border-radius: 50%;
            animation: ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            border-color: var(--primary-color-background) transparent transparent transparent;

            &:nth-child(1) {
                animation-delay: -0.45s;
            }

            &:nth-child(2) {
                animation-delay: -0.3s;
            }

            &:nth-child(3) {
                animation-delay: -0.15s;
            }
        }
    }
}

@keyframes :global(ring) {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}