@mixin respond-to($breakpoint, $landscape: false, $portrait: false) {
    $query-width: map-get($breakpoints, $breakpoint);

    @if $query-width {
        $query: unquote("(min-width: #{$query-width})");

        @if $landscape {
            $query: unquote("#{$query} and (orientation: landscape)");
        }
        @if $portrait {
            $query: unquote("#{$query} and (orientation: portrait)");
        }

        @media #{$query} {
            @content;
        }
    } @else {
        @error 'No value found for `#{$breakpoint}`. '
        + 'Please make sure it is defined in `$breakpoints` map.';
    }
}

@mixin gradient($direction, $color) {
    $gradient: linear-gradient(
        unquote($direction),
        $color 0%,
        rgba($color, 0.738) 19%,
        rgba($color, 0.541) 34%,
        rgba($color, 0.382) 47%,
        rgba($color, 0.278) 56.5%,
        rgba($color, 0.194) 65%,
        rgba($color, 0.126) 73%,
        rgba($color, 0.075) 80.2%,
        rgba($color, 0.042) 86.1%,
        rgba($color, 0.021) 91%,
        rgba($color, 0.008) 95.2%,
        rgba($color, 0.002) 98.2%,
        rgba($color, 0) 100%
    );
    background-image: $gradient;
}

@mixin ellipse() {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

@mixin truncate($lines, $line-height, $font-size: 1em) {
    line-height: $line-height;
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
    max-height: $lines * $line-height * $font-size;
    font-size: $font-size;
    overflow: hidden;
}
