@import "../../../styles/variables";

.base {
    border-bottom: 1px solid $line-grey;
    @include respond-to("medium") {
        width: calc(50% - 0.7rem);
    }
}
.toggleButton {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    min-height: 5.62rem;
    max-width: 100%;
    width: 100%;
    margin: 0;
    padding: 0.8125rem;
    color: var(--primary-color-font);
    text-align: left;
    background-color: transparent;
    border-radius: 0;
    appearance: none;
    box-shadow: none;
    cursor: pointer;

    &:hover {
        background-color: transparent;
    }
}
.question {
    margin: 0;
    padding-left: 1rem;
    font-family: $font-secondary;
    font-size: get-font-size("heading two", em);
    flex: 1;
    color: var(--primary-color-background);
}
.answerWrap {
    overflow: hidden;
    transition: height 0.3s ease;
}
.answer {
    display: block;
    margin: 1rem 0;
    padding-left: 4.6rem;

    a {
        color: get-color("accent", "base");
        text-decoration: underline;
        transition: color 0.3s ease;
        &:visited {
            color: get-color("accent", "base");
        }
        &:hover {
            color: get-color("text", "base");
        }
    }
}
