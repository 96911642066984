@import "../../../styles/variables";

.base {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.8125rem;
    width: 2.8125rem;
    background-color: get-color("background", "base", 1);
    border: 2px solid $grey;
    border-radius: 3rem;
    transition: background-color 0.3s ease;

    &Grey {
        background-color: get-color("background", "light", 1);
    }
}
.icon {
    height: 1rem;
    width: 1rem;

    g {
        fill: var(--primary-color-font);
    }
}
.iconRect {
    transition: transform 0.3s ease;
    transform: rotate(0);
    transform-origin: 50%;

    &Horizontal {
        transform: rotate(90deg);
    }
}
