$gutter: 40px;
$spacing: 30px;

$border-radius: 8px;

// menu sizes
$menu-height: 4.375rem;
$menu-height-desktop: 6.25rem;
$menu-height--large: 4.5rem;
$menu-height--large-desktop: 8.75rem;
$menu-height--larger-desktop: 10.5rem;
