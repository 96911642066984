*,
*::before,
*::after {
    box-sizing: border-box;
}

*:focus {
    outline: none;
}

html {
    font-family: $font-primary;
    background-color: get-color("background");
    background: get-color("background");
    color: var(--primary-color-font);
    font-size: get-font-size("base", px);
    line-height: get-line-height("base");
    font-weight: normal;

    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
}

body {
    min-height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    background: white;
    padding: 0.3em;
}

input,
textarea {
    font-size: initial;
}
