h1 {
    font-size: get-font-size("heading one");
    line-height: get-line-height("heading one");
    margin-top: 0;
    margin-bottom: $spacing;
    font-weight: normal;
}

h2 {
    font-size: get-font-size("heading two");
    line-height: get-line-height("heading two");
    margin-top: 0;
    margin-bottom: $spacing;
    font-weight: normal;
}

a {
    color: var(--primary-color-font);
    text-decoration: none;
}

small {
    font-size: 0.7em;
    line-height: 1;
}

hr {
    border: none;
    border-bottom: 1px solid $navy;
    margin-bottom: $spacing;
}

p {
    margin-top: 0;
    margin-bottom: 0;

    & + & {
        margin-top: 1em;
    }
}

button {
    display: block;
    width: 100%;
    max-width: 15.125rem;
    padding: 0.85rem;
    margin: 0.3125rem auto;
    color: $white;
    text-align: center;
    background: var(--secondary-color-background);
    border: none;
    border-radius: 3rem;
    -webpack-appearance: none;
    box-shadow: $button-shadow;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
        color: var(--primary-color-font);
        background-color: get-color("background", "base", 1);
    }
}
