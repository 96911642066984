@import "../../../styles/helpers";

.video {
    &Card {
        background-color: get-color("background");
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: column;
    }
}

.title {
    .videoCard & {
        order: 2;
        text-align: center;
        margin-bottom: 1rem;
    }

    @include respond-to("medium") {
        font-size: get-font-size("small title", em);
    }
}

.videoWrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    overflow: hidden;

    .videoCard & {
        margin-bottom: 1rem;

        @include respond-to("medium") {
            margin-bottom: 2rem;
        }
    }

    iframe,
    .poster {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    > div {
        cursor: pointer;
    }
}

.poster {
    background-repeat: no-repeat;
    background-size: cover;
}

.icon {
    width: 74px;
    height: 74px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -37px;
    margin-top: -37px;
    background-repeat: no-repeat;
    background-size: 100%;

    @include respond-to("medium") {
        width: 148px;
        height: 148px;
        margin-left: -74px;
        margin-top: -74px;
    }

    &Default {
        background-color: var(--primary-color-background);
        border-radius: 50%;
        background-image: url("/assets/icons/icon-video.svg");
        background-position: 60% center;
        background-size: 40%;
        box-shadow: 0 2px 11px rgba(0, 0, 4, 0.2);

        @include respond-to("medium") {
            background-size: 53px 69px;
        }
    }
}

.uploadedVideo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    margin: 0;
    padding: 0;
}
