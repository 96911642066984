// @import 'styles/variables';
@import "../../../styles/variables";

$container: 1200px;
$container--slim: 588px;

.container {
    width: 100%;
    max-width: $container;
    margin-left: auto;
    margin-right: auto;
    padding: 0 $gutter * 0.5;

    @include respond-to("medium") {
        padding-left: $gutter;
        padding-right: $gutter;
    }

    &.isFull {
        max-width: 100%;
    }

    &.isSlim {
        max-width: $container--slim;
    }
}
