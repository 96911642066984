@import "../../../styles/variables";
$dialog-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
$dialog-shadow-reversed: 0px 0px 4px rgba(0, 0, 0, 0.1);
$hover-shadow: 5px 4px 9px rgba(126, 126, 126, 0.52);

.container {
    max-width: 36.75rem;
    margin: 0 auto;
}

.loader {
    margin-top: 3rem;
    text-align: center;
}

.title {
    margin-bottom: 0.875rem;

    &--send {
        text-align: center;
    }
}

.submitButton {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 16rem;
    padding: 0.875rem;
    color: var(--primary-color-font);
    font-size: get-font-size("base");
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 2rem;
    box-shadow: $dialog-shadow;
    background-color: var(--primary-color-background);
    transition: all 0.2s ease;
    cursor: pointer;

    &:hover {
        &:after {
            opacity: 1;
        }
    }

    &:after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: "";
        border-radius: 2rem;
        box-shadow: $hover-shadow;
        opacity: 0;
        transition: opacity 0.3s ease;
    }
}

.link {
    color: var(--primary-color-background);
    text-decoration: underline;

    &:visited {
        color: var(--primary-color-background);
    }
    &:hover {
        color: var(--secondary-color-background);
    }
}

.terms {
    display: flex;
    margin-bottom: 1.75rem;

    &Label {
        font-size: get-font-size("base");
        margin-left: 0.625rem;
        width: 80%;
        max-width: 25rem;
    }
    &Input {
        position: absolute;
        opacity: 0;
    }
    &Checkbox {
        display: inline-block;
        margin-right: 0.4rem;
        height: 1.125rem;
        width: 1.125rem;
        content: "";
        border: 1px solid var(--primary-color-background);
        border-radius: 2px;
        background-color: get-color("background", "base");
    }
    input:checked + &Checkbox {
        background-image: url("/assets/icons/ic-checkmark-form.svg");
        background-position: center;
        background-size: contain;
    }
    input:focus + &Checkbox {
        border: 1px solid var(--primary-color-background);
    }
}

.brandName {
    text-transform: capitalize;
}

.sendButton {
    margin-left: 0;
    margin-right: 0;
}

.campaignText {
    margin-bottom: 1.5rem;
}