@import "styles/variables";

$dialog-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
$link-shadow: 2px 2px 2px rgba(126, 126, 126, 0.52);
$text-width--small: 26.25rem;
$text-width--medium: 46.25rem;
$spacing: 1.875rem;
$margin: 1.25rem;

.container {
    position: relative;
    line-height: 1.6;

    img {
        display: block;
        margin: 0.5rem auto;
        width: 100%;
        max-width: 212px;
        text-align: center;
    }

    a {
        text-decoration: underline;

        &:hover {
            text-shadow: $link-shadow;
            transition: text-shadow 0.3s ease;
        }

        &:hover:active {
            text-shadow: none;
            transition: text-shadow 0.3s ease;
        }
    }
}

.hero {
    position: relative;
    min-height: 40vh;
    width: 100%;
    padding: 2rem 0;
    background-color: get-color("accent", "base");
    overflow: hidden;

    @include respond-to("medium") {
        min-height: 50vh;
        padding: 2rem;
    }
}

.image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.title {
    position: relative;
    color: get-color("accent", "light");
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 1);
    font-size: get-font-size("heading one");

    .center & {
        text-align: center;
    }

    .right & {
        text-align: right;
    }

    margin-bottom: $margin;
    margin-inline-start: 0;
    margin-inline-end: 0;
    max-width: 100%;

    @include respond-to("medium") {
        font-size: get-font-size("megatitle");
    }
}

.text {
    max-width: $text-width--small;
    font-size: get-font-size("subtitle");
    font-weight: 700;
    line-height: 1.5rem;
    text-align: left;

    @include respond-to("medium") {
        line-height: 1.8125rem;
    }

    background-color: rgba(#f8f8f8, 0.85);
    box-shadow: $dialog-shadow;
    padding: 1.5rem;
    color: get-color("text", "base");

    .center & {
        max-width: $text-width--small * 1.4;
        margin-left: auto;
        margin-right: auto;
    }

    .right & {
        margin-left: auto;
    }

    &.noBackground {
        padding: 0 0 1rem 0;
        background-color: transparent !important;
        color: get-color("accent", "light");
        text-shadow: 1px 1px 4px rgba(0, 0, 0, 1);
        box-shadow: unset;
    }
}

.buttonContainer {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .center & {
        justify-content: center;
    }

    .right & {
        justify-content: flex-end;
    }
}

.button {
    margin: 0;
    margin-top: 1rem;
    min-width: 15.625rem;
    text-align: center;
}

.readMoreButton {
    margin: 0;
    margin-top: 1rem;
    text-align: center;
}