// brand colors
$navy: #012069;
$bluebird: #02a6d6;
$cobalt: #0938a5;
$pigeon: #677699;
$airforce: #4b81a8;
$night: #021644;
$gloom: #f1f1f1;
$grey: #ececec;
$dark-grey: #8c8c8c;
$line-grey: #d8d8d8;
$lightgrey: #ebf0f2;
$pink: #e5719b;

// validation colors
$success: #5daa1a;
$error-1: #e9e9ea;
$error-2: #d52b1e;

// default
$white: #ffffff;
$black: #000000;

// shadows
$text-shadow: 0 2px 20px rgba(0, 0, 0, 0.41);
$button-shadow: 0 2px 10px rgba(38, 17, 88, 0.26);
$readmore-shadow: 0 2px 11px rgba(0, 0, 0, 0.16);
$box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);

// Semi transparent colors
$overlay: rgba(0, 0, 0, 0.3);

$colors: (
    "text": (
        "base": $navy,
        "light": $white,
        "medium": $dark-grey,
        "dark": $pigeon,
    ),
    "background": (
        "base": $white,
        "light": $grey,
        "medium": $cobalt,
        "dark": $navy,
        "darker": $night,
    ),
    "theme": (
        "base": $navy,
        "light": $bluebird,
        "dark": $night,
    ),
    "accent": (
        "base": $bluebird,
        "light": $lightgrey,
    ),
);

@function get-color($name, $variation: "base", $opacity: 1) {
    $color-spectrum: map-get($colors, $name);

    @if $color-spectrum {
        $color: map-get($color-spectrum, $variation);
        @if ($color) {
            @if $opacity < 1 {
                @return rgba($color, $opacity);
            }
            @return $color;
        } @else {
            @warn "color variation from `#{$name}` does not exist: `#{$variation}`";
        }
    } @else {
        @warn "color name does not exist: `#{$name}`";
    }
    @return rebeccapurple;
}
