@import "../../../styles/variables";

.base {
    margin: 0 0 0.9375rem 0;
    padding: 0;
    border-width: 0px;
}

.title {
    font-family: $font-primary;
}

.input {
    @extend %input;
}

.textArea {
    height: 10.5rem;
    resize: none;
}

.selectWrap {
    position: relative;

    &:not(:last-child) {
        margin-bottom: 0.625rem;
    }

    &::after {
        position: absolute;
        top: 50%;
        right: 1.125rem;
        height: 0.562rem;
        width: 0.5625rem;
        background-image: url("/assets/icons/icon-arrowdown.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        transform: translateY(-50%);

        @supports (appearance: none) {
            content: "";
        }
    }
}

.select {
    appearance: none;
    color: var(--primary-color-font);
    background-color: var(--primary-color-background);
    border-color: $navy;
    border-radius: 0;

    &::before {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
    }
}

.radioWrap {
    margin: 0 0 1rem 0;
}

.radioTitle {
    margin-bottom: 0.5rem;
}

.radioLabel {
    display: inline-block;
    margin-left: 1rem;
}

.radioLabelWrap {
    display: block;
}

.file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    left: 0;
    z-index: -1;
}

.fileLabel {
    display: block;
    width: 100%;
    max-width: 15.125rem;
    padding: 0.85rem;
    margin: 0 auto 1rem auto;
    color: var(--primary-color-background);
    font-family: $font-primary;
    text-align: center;
    background-color: get-color("text", "light", 1);
    border: 2px solid currentColor;
    border-radius: 3rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    margin-left: 0;

    @include respond-to("medium") {
        display: inline-block;
    }

    &:focus {
        box-shadow: 0 0 0 4px get-color("theme", "light", 1);
    }

    &:hover {
        color: var(--primary-color-font) !important;
        background-color: get-color("background", "base", 1) !important;

        &:focus {
            box-shadow: 0 0 0 4px get-color("theme", "light", 1);
        }
    }

    &Caps {
        text-transform: uppercase;
    }

    &IsSquareRounded {
        border-radius: 0.375rem;
    }

    &PrimaryFont {
        font-family: $font-primary;
    }

    &SecondaryFont {
        font-family: $font-secondary;
    }
}

.fileCount {
    display: block;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 2rem;

    @include respond-to("medium") {
        display: inline-block;
        margin-left: 1rem;
    }
}

.requiredLabel {
    font-family: $font-primary;
    font-size: 0.8rem;
    font-style: italic;
    line-height: 1.5rem;
}

.textDate {
    color: get-color("text", "medium", 0.6);
}