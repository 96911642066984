@use "sass:math";

@import "../../../styles/variables";

.footer {
    position: relative;
    background-color: get-color("background");

    &Shadow {
        box-shadow: 0 -4px 12px rgba(black, 0.1);
    }
}

.footerMenu {
    background-color: var(--primary-color-background);
    width: 100%;
    color: get-color("text", "light");
    padding: math.div($gutter, 1.33333333) 0;

    @include respond-to("medium") {
        padding: $gutter 0;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        text-align: center;
        width: 100%;

        @include respond-to("medium") {
            display: flex;
            width: auto;
        }

        a {
            color: get-color("text", "light");
            color: inherit;
            display: block;
            padding: 1rem;
            font-size: get-font-size("base large");
            line-height: get-line-height("base large");
            text-decoration: underline;
        }
    }
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include respond-to("medium") {
        flex-direction: row;
        justify-content: space-between;
    }
}

.logo {
    display: block;
    width: 88px;
    height: 100%;
    margin: 0 auto 1.5rem;

    @include respond-to("medium") {
        margin: 0 auto;
    }
}

.footerInfo {
    background-color: var(--secondary-color-background);
    width: 100%;
    color: get-color("text", "light");
    padding: $gutter * 0.5 0;
    text-align: center;

    .container {
        @include respond-to("medium") {
            flex-direction: row-reverse;
        }
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        margin-bottom: 1rem;

        @include respond-to("medium") {
            margin-bottom: 0;
        }
    }

    a {
        color: get-color("text", "light");
        height: 1.5rem;
        width: 1.5rem;
        margin: 0.5rem;
        display: flex;
        align-items: center;
    }

    small {
        color: rgba(get-color("text", "light"), 0.4);
        font-size: get-font-size("base large");
        line-height: get-line-height("base large");
    }

    img {
        display: block;
        width: 100%;
    }
}
