@import "../../../styles/variables";

.container {
    @supports (display: flex) {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    background-color: var(--primary-color-background);
    color: get-color("text", "light");
    height: calc(100vh - #{$menu-height});
    width: 100%;
    position: relative;
    overflow: hidden;
    text-align: center;
    position: fixed;
    z-index: 0;

    &:after {
        position: absolute;
        content: "";
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(var(--secondary-color-background), 0.2);
        z-index: -1;
    }

    @include respond-to("medium") {
        height: calc(100vh - #{$menu-height-desktop});
        border-bottom: $gutter * 0.5 solid get-color("background");
        background-attachment: fixed;
    }

    &Small {
        height: calc(100vh - #{$menu-height--large});

        @include respond-to("medium") {
            height: calc(100vh - #{$menu-height--large-desktop});
        }
    }
}

.backgroundImage {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url("/assets/bg-image.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.containerPlaceholder {
    height: calc(100vh - #{$menu-height});
    width: 100%;
    pointer-events: none;

    &Small {
        height: calc(100vh - #{$menu-height--large});

        @include respond-to("medium") {
            height: calc(100vh - #{$menu-height--large-desktop});
        }
    }
}

.imageWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;

    @supports (height: auto) {
        height: auto;
    }
}

.image {
    display: block;
    width: 15rem;
    transform: scale(0.5);

    @supports (height: auto) {
        height: auto;
    }

    @include respond-to("medium") {
        width: 31.25rem;
    }

    @include respond-to("x-large") {
        width: 37.5rem;
    }
}

.more {
    display: none;

    @include respond-to("medium") {
        border-radius: 0;
        display: flex;
        flex-direction: column;
        position: absolute;
        width: 10rem;
        padding: 0;
        margin-left: calc(50% - 5rem);
        margin-right: calc(50% - 5rem);
        bottom: 3rem;
        align-items: center;
        font-family: $font-primary;
        color: get-color("text", "light");
        background-color: transparent;
        box-shadow: none;
        z-index: 1;
        cursor: pointer;

        &:hover {
            background-color: transparent;
            color: get-color("text", "light");
        }

        @supports (align-items: center) {
            width: auto;
            margin-left: auto;
            margin-right: auto;
            left: 50%;
            transform: translateX(-50%);
        }

        img {
            display: block;
        }
    }
}

.mask {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
}
