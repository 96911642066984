@import "../../../styles/variables";

.base {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 23rem;
}
.title {
    font-family: $font-secondary;
    font-size: get-font-size("title", em);
}
.text {
    margin-bottom: 2rem;
}
