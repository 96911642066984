@import "../../../styles/variables";

.menu {
    background-color: var(--primary-color-background);
    color: get-color("text", "light");
    width: 100%;
    height: $menu-height;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;

    @include respond-to("medium") {
        height: $menu-height-desktop;
        z-index: 2;
    }

    &Larger {
        @include respond-to("medium") {
            height: $menu-height--larger-desktop;
        }
    }

    &Center {
        height: $menu-height--large;

        @include respond-to("medium") {
            height: $menu-height--large-desktop;
        }
    }

    &Shadow {
        box-shadow: 0 4px 12px rgba(black, 0.1);
    }
}

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    @include respond-to("medium") {
        justify-content: space-between;
    }
}

.menuPlaceholder {
    height: $menu-height;

    @include respond-to("medium") {
        height: $menu-height-desktop;
    }

    &Larger {
        @include respond-to("medium") {
            height: $menu-height--larger-desktop;
        }
    }

    &Center {
        height: $menu-height--large;

        @include respond-to("medium") {
            height: $menu-height--large-desktop;
        }
    }
}

.logo {
    display: block;
    width: auto;
    height: $menu-height - 2.625rem;
    opacity: 0;
    transition: none;

    @include respond-to("medium") {
        height: $menu-height-desktop - 3.375rem;
    }

    &Large {
        height: 3.125rem;

        .menuCenter & {
            height: 3.125rem;
        }

        @include respond-to("medium") {
            height: 6.875rem;

            .menuCenter & {
                height: 10rem;
            }
        }
    }

    &Centered {
        width: 100%;
    }

    &Wrap {
        display: none;

        @include respond-to("medium") {
            display: block;
            height: 5rem;
            width: 5rem;
            transition: opacity 0.3s ease;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }

        &Large {
            @include respond-to("medium") {
                position: absolute;
                top: 1.5rem;
                left: 50%;
                height: 10rem;
                width: 10rem;
                background-position: bottom;
                transform: translate(-50%, 0);
            }
        }
    }

    .menuCenter & {
        @include respond-to("medium") {
            position: absolute;
            left: 50%;
            top: 1.25rem;
            transform: translate(-50%, 0);
        }
    }
}

.logoVisible {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
}

.navigation {
    background-color: var(--primary-color-background);
    color: get-color("text", "light");
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    display: flex;
    align-items: center;
    pointer-events: none;

    @include respond-to("medium") {
        opacity: 1;
        visibility: visible;
        height: auto;
        z-index: 2;
        width: 100%;
        background-color: transparent;
        left: auto;
        right: 0;
        height: $menu-height-desktop;
    }

    &Larger {
        @include respond-to("medium") {
            height: $menu-height--larger-desktop;
        }
    }

    &Center {
        @include respond-to("medium") {
            height: $menu-height--large-desktop;
        }
    }

    &.isActive {
        opacity: 1;
        visibility: visible;
    }

    &.hasCaps {
        .link {
            text-transform: uppercase;
        }
    }
}

.navList {
    list-style: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 1rem;
    margin: 0;

    @include respond-to("medium") {
        flex-direction: row;
        justify-content: flex-end;
        padding: 0;
    }

    .navigationCenter & {
        @include respond-to("medium") {
            justify-content: center;
        }
    }

    &Part {
        @include respond-to("medium") {
            display: flex;
            align-items: center;
            width: calc(50% - 6rem);
        }

        &Left {
            justify-content: flex-end;
        }
        &Right {
            justify-content: flex-start;
        }
    }

    &LogoSpace {
        @include respond-to("medium") {
            position: relative;
            height: 5rem;
            width: 5rem;
            margin: 0 2.5rem;
        }

        &Large {
            @include respond-to("medium") {
                height: 10rem;
                width: 10rem;
            }
        }
    }
}

.listItem {
    width: 100%;
    pointer-events: auto;

    @include respond-to("medium") {
        width: auto;
    }
}

.link {
    color: get-color("text", "light");
    color: inherit;
    padding: 1rem;
    display: block;
    font-size: get-font-size("menu");
    line-height: get-line-height("menu");

    @include respond-to("medium") {
        font-size: get-font-size("menu desktop");
        line-height: get-line-height("menu desktop");
    }

    &Active {
        font-weight: bold;
    }
}

.hamburger {
    position: absolute;
    right: 5px;

    @include respond-to("medium") {
        display: none;
    }
}
