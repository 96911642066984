@import "../../../styles/variables";

.title {
    display: block;
    margin-bottom: 0.5rem;
}
.selectWrap {
    position: relative;
    &:not(:last-child) {
        margin-bottom: 0.625rem;
    }
    &::after {
        position: absolute;
        top: 50%;
        right: 1.125rem;
        height: 0.562rem;
        width: 0.5625rem;
        background-image: url("/assets/icons/icon-arrowdown.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        transform: translateY(-50%);
        @supports (appearance: none) {
            content: "";
        }
    }
}

.input {
    @extend %input;
    padding-right: 2.5rem;
}
