@import "../../../styles/helpers";

$text-line-height: 1.6;

.title {
    text-align: center;

    &Detail {
        margin-bottom: 0.2rem;
        font-size: get-font-size("base large", em);
        letter-spacing: 0.1rem;

        .itemDetailTitleLarge & {
            @include respond-to("medium") {
                font-size: get-font-size("title", em);
            }
        }
    }
}

.container {
    padding-top: 2em;
    &Detail {
        padding-top: 0;
    }
}

.carousel {
    position: relative;

    &Wrapper {
        overflow: hidden;
    }

    &Slide {
        transition: 300ms ease-in-out;
        transition-property: opacity, transform;

        .isDesktop & {
            display: flex;
            justify-content: space-between;
        }
    }

    &Button {
        position: absolute;
        top: 50%;
        left: 2em;
        transform: translateY(-50%);
        z-index: 1;
        cursor: pointer;

        &Detail {
            top: auto;
            @include respond-to("medium") {
                top: 50%;
            }
        }

        &.isNext {
            left: auto;
            right: 2em;
        }
    }
}

.item {
    width: 100%;
    padding: 5px;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    &Detail {
        padding: 0;
    }

    @include respond-to("large") {
        width: 33.3333%;

        &Detail {
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: center;
            width: 100%;
        }
    }
    &Titles {
        position: relative;
        width: 100%;
        padding: 1.4375rem 1rem;

        @include respond-to("large") {
            margin-bottom: 3rem;
        }

        &ExtraPadding {
            @include respond-to("medium") {
                padding-top: 3.75rem;
            }
        }

        .itemDetailTitleLarge & {
            @include respond-to("medium") {
                padding: 3.375rem 1rem 1rem;
                margin-bottom: 1rem;
            }
        }
    }
    &Title {
        margin: 0;
        padding: 0;
        font-size: get-font-size("heading one", em);
        letter-spacing: 0.1rem;
        text-align: center;

        .itemDetailTitleLarge & {
            @include respond-to("medium") {
                font-size: get-font-size("megatitle desktop", em);
            }
        }
    }

    &LeftColumn,
    &RightColumn {
        @include respond-to("large") {
            max-width: 34rem;
            width: 50%;
        }
    }

    &RightColumn {
        padding: 1rem 0;
        display: block;
    }

    &LeftColumn {
        padding: 1rem;
    }

    &Text {
        max-width: 30rem;
        font-size: get-font-size("base", em);
        line-height: $text-line-height;
        text-align: center;
    }

    &Arrow {
        width: 0.9rem;
        margin: 0.75rem 0 0 0;
        @include respond-to("large") {
            display: none;
        }
    }

    &ImageContainer {
        width: 100%;
        height: 100%;
    }

    &Image {
        width: 100%;
        background: center bottom no-repeat;
        height: 52vh;
        background-size: contain;
        display: block;

        @include respond-to("large") {
            background-size: auto 100%;
        }

        @include respond-to("x-large") {
            background-size: auto 88%;
        }

        &::after {
            content: "";
            display: block;
            width: 100%;
            height: 0;
            // padding-bottom: 160%;
            padding-bottom: 110%;

            @include respond-to("large") {
                padding-bottom: 120%;
            }
        }

        img {
            display: none;
        }
    }

    &Button {
        color: white;
        background-color: var(--primary-color-background);
        text-align: center;
        min-width: 70%;
        padding: 0.6em 1em;
        border-radius: 10em;
        box-shadow: 0 2px 10px rgba(#261158, 0.26);
        white-space: nowrap;
        overflow: hidden;
        transition: opacity 0.3s ease;

        &:hover {
            opacity: 0.8;
        }
    }
}

.itemDetailsLeft {
    width: 100%;
    color: white;

    .itemImage {
        width: 100%;
        background-size: cover;
        background-position: center;

        @include respond-to("large") {
            height: 100%;
            position: absolute;
        }
    }
}

.flex {
    width: 100%;
    display: flex;
    flex-direction: column;

    @include respond-to("large") {
        flex-direction: row;
    }
}

.detailsLeft {
    color: get-color("text", "light");
    order: 2;
    width: 100%;

    @include respond-to("large") {
        width: 50%;
        order: 1;
    }

    .itemDetailsLeftWider & {
        @include respond-to("large") {
            width: 57%;
        }
    }

    .content {
        display: block;
        visibility: visible;

        @include respond-to("large") {
            visibility: hidden;
            position: relative;
        }

        .contentContainer {
            .itemDetailsLeftWider & {
                width: 100%;

                @include respond-to("medium") {
                    width: 60%;
                }

                @include respond-to("large") {
                    width: 30%;
                }
            }
        }
    }
}

.detailsRight {
    width: 100%;
    order: 1;
    position: relative;

    @include respond-to("large") {
        width: 50%;
        order: 2;
    }

    .itemDetailsLeftWider & {
        @include respond-to("large") {
            width: 43%;
        }
    }
}

.content {
    display: none;
    visibility: hidden;

    @include respond-to("large") {
        display: block;
        visibility: visible;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
}

.contentContainer {
    padding: 3rem 0.9375rem;

    @include respond-to("large") {
        width: 50%;
        height: 100%;
        padding: 3rem 3rem 0 0;
    }

    .itemDetailsLeft & {
        padding: 3rem 3rem 3rem 0;
    }

    .itemDetailsLeftWider & {
        @include respond-to("large") {
            width: 30%;
            padding: 3rem 3rem 3rem 0;
        }
    }

    .title {
        margin-bottom: 1rem;
    }

    .itemTitle {
        font-size: 28px;
        margin: 0 0 2rem 0;

        @include respond-to("large") {
            font-size: 48px;
        }
    }

    * {
        text-align: left;
    }
}

.itemDetailButton {
    margin: 1rem 0 0 0;
    text-align: center;
}

.productInfo {
    position: relative;
    max-width: 21.5625rem;
    margin: 2rem 0;
    padding: 1.5625rem;
    border: 2px solid $navy;

    &:before,
    &:after {
        position: absolute;
        left: 1.4375rem;
        right: 1.4375rem;
        content: "";
    }
    &:before {
        top: -1rem;
        border-top: 2px solid $navy;
    }
    &:after {
        bottom: -1rem;
        border-bottom: 2px solid $navy;
    }

    @include respond-to("large") {
        margin: 2rem auto;
    }

    @include respond-to("x-large") {
        max-width: 23.75rem;
    }

    &Default {
        border: 0;
        border-radius: 2px;
        background-color: get-color("background");
        box-shadow: 0 0px 4px 0 rgba($black, 0.15);

        &:before,
        &:after {
            display: none;
            border: 0;
        }
    }

    &Centered {
        border: 0;
        border-radius: 2px;
        background-color: get-color("background", "base");
        box-shadow: $box-shadow;
        color: var(--primary-color-font);

        @include respond-to("large") {
            margin: 0;
            position: absolute;
            left: 50%;
            top: 4rem;
            transform: translateX(-50%);
        }

        &:before,
        &:after {
            display: none;
            border: 0;
        }
    }

    &Left {
        border: 0;
        margin: 0;
        padding: 0;
        margin-top: 3rem;

        &:before,
        &:after {
            display: none;
            border: 0;
        }
    }

    &Section {
        &:not(:first-of-type) {
            margin-top: 2.125rem;
        }
    }
    &Title {
        margin-bottom: 0.8rem;
        font-family: $font-secondary;
        font-size: get-font-size("heading two", em);

        .productInfoLeft &,
        .productInfoCentered &,
        .productInfoDefault & {
            font-family: $font-primary;
            text-transform: uppercase;
            font-weight: normal;
            margin: 0;
            color: var(--primary-color-background);
        }

        .productInfoDefault & {
            font-family: $font-secondary;
        }

        .productInfoCentered & {
            font-weight: bold;
            margin-bottom: 1rem;
        }
    }
    &Text {
        font-size: get-font-size("base", em);
        line-height: $text-line-height;
    }
    &List {
        padding-left: 0;
        font-size: get-font-size("base", em);
        line-height: $text-line-height;
    }
    &ListItem {
        display: flex;
        justify-content: space-between;
        list-style: none;
    }
}

.button {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: transparent;
    background-image: linear-gradient(
        to top,
        rgba(white, 0),
        rgba(white, 0.85)
    );
    box-shadow: 0 2px 10px rgba(black, 0.2);
    text-align: center;

    &Icon {
        fill: $navy;
        width: 8px;
        transform: rotate(180deg);

        .isNext & {
            transform: none;
        }
    }
}
