@import "mixins";
@import "snippets";
@import "variables/colors";
@import "variables/typography";
@import "variables/sizes";
@import "variables/breakpoints";

// typography
$font-primary: "__primary", Arial, sans-serif;
$font-secondary: "__secondary", Arial, sans-serif;

$font-size-base: 16;
$font-size: $font-size-base * 1px;

// sizes
$border-radius: 8px;
