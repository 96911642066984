@import "../../../styles/variables";

@keyframes cropVideo {
    100% {
        transform: translate(-50%, -50%) scale(1);
    }
}

.video {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 100%;

    @media screen and (orientation: landscape) {
        position: absolute;
        width: 100%;
        height: auto;
        transform: translate(-50%, -50%) scale(1.5);
        animation: cropVideo 1s ease 1s forwards;
    }
}
