@import "../../../styles/variables";

.base {
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $overlay;
    z-index: 3;
}
.content {
    position: absolute;
    left: 50%;
    top: 50%;
    max-width: 36.8125rem;
    width: calc(100% - 3.15rem);
    padding: 2.5rem 2rem 2rem 2rem;
    text-align: center;
    background-color: get-color("background", "base", 1);
    box-shadow: $readmore-shadow;
    transform: translate(-50%, -50%);
}
.title {
    margin: 0 0 0.7rem 0;
    font-family: $font-secondary;
    font-size: get-font-size("menu desktop", em);
    text-transform: uppercase;
}
.text {
    font-size: get-font-size("base large", em);
    max-width: 23.125rem;
    margin: 0 auto 1.625rem auto;
}
.closeButton {
    position: absolute;
    height: 1.3125rem;
    width: 1.3125rem;
    top: 1rem;
    right: 1rem;
    padding: 0;
    background-color: transparent;
    border: 0px;
    box-shadow: none;
    cursor: pointer;
}
.closeImg {
    height: 100%;
    width: 100%;
}
.list {
    padding: 0;
}
.listItem {
    text-align: left;
    list-style: none;
}
.link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 10.3125rem;
    margin: 0 auto;
    padding: 0.8rem 0.5rem;
    width: 100%;
    border-bottom: 1px solid $line-grey;
    @include respond-to("small") {
        max-width: 14.75rem;
    }
}
.storeName {
    font-size: get-font-size("base large", em);
    text-decoration: underline;
}
.arrow {
    height: 0.8125rem;
    width: 0.625rem;
}
