@import "../../../styles/variables";

.base {
    padding: 1rem 0;
    @include respond-to("medium") {
        padding: 0;
    }
}

.title {
    margin-bottom: 0.875rem;
}
.faqItems {
    @include respond-to("medium") {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-flow: wrap;
    }
}
