@import "../../../styles/variables";

.backgroundImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--primary-color-background);
    background-size: cover;
    background-position: 50% 50%;
    // with filter transition of lazy image load
    transition: transform 0.25s ease, filter 0.25s ease 0s !important;
    transform: scale(1);
}
