@import "../../../styles/variables";

.module {
    padding-bottom: 0.5rem;

    &:first-of-type {
        padding-top: 0.5rem;
    }

    @include respond-to("medium") {
        padding-bottom: 2.5rem;

        &:first-of-type {
            padding-top: 2.5rem;
        }
    }
}

.moduleMargin {
    &:last-of-type {
        padding-bottom: 0;
    }
}
