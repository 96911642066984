@import "../../../../styles/variables";

.base {
    width: 100%;

    @include respond-to("medium") {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: auto;

        &Reversed {
            flex-direction: row-reverse;
        }
    }
}
.imgWrap {
    position: relative;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
    overflow: hidden;

    @include respond-to("medium") {
        padding-top: 0;
        width: calc(50% - 2rem);
    }
}
.img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);

    @include respond-to("medium") {
        position: relative;
        top: auto;
        left: auto;
        transform: none;
        display: block;
        object-fit: contain;
    }
}

.content {
    @include respond-to("medium") {
        width: calc(50% - 2rem);
        max-width: 35rem;
        margin-left: auto;
        padding: 1rem 0;

        &Reversed {
            margin-left: 0;
            margin-right: auto;
        }
    }

    @include respond-to("x-large") {
        padding: 0;
    }
}

.title {
    margin: 2rem 0 1rem;

    @include respond-to("medium") {
        margin: 0 0 1.2rem 0;
    }

    &Line {
        &:after {
            content: "";
            height: 0.25rem;
            width: 3.75rem;
            margin: 0.25rem 0 0 0;
            background: var(--primary-color-background);
            display: block;
        }

        &Gold {
            &:after {
                background-image: linear-gradient(
                    -58deg,
                    #a37e50 3%,
                    #d7bb96 31%,
                    #feebdb 74%,
                    #b59771 100%
                );
            }
        }

        &Blue {
            &:after {
                background-color: #004caa;
            }
        }
    }
}

.text {
    margin: 0 0 1rem;
    font-size: get-font-size("base", em);
    @include respond-to("medium") {
        margin: 0 0 1.6875rem 0;
    }
    @include respond-to("large") {
        font-size: get-font-size("base large", em);
    }
}

.button {
    margin: 0;
}
